import React, { useEffect, useState, Suspense } from 'react';
import FeatherIcon from 'feather-icons-react';
import CanvasNest from 'canvas-nest.js';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { use100vh } from 'react-div-100vh';
import styles from '../../assets/scss/home.module.scss';
const Navbar = React.lazy(() => import('../../component/CustomNav'));
const Home = React.lazy(() => import('../Home'));
const Introduction = React.lazy(() => import('../Introduction'));
const Partner = React.lazy(() => import('../Partner'));
const Footer = React.lazy(() => import('../Footer'));
const Product = React.lazy(() => import('../Product'));
const Advantage = React.lazy(() => import('../Advantage'));
const AdvantageMobile = React.lazy(() => import('../Advantage/mobile'));
const IntroductionMobile = React.lazy(() => import('../Introduction/mobile'));
const HomeMobile = React.lazy(() => import('../Home/mobile'));
const NavbarMobile = React.lazy(() => import('../../component/CustomNav/mobile'));

function Layout() {
	const [show, setShow] = useState(false);
	const [hide, setHide] = useState(false);

	function bindClick(e) {
		const dom = document.getElementById('helper');
		if (dom && !dom.contains(e.target)) {
			setShow(false);
		}
	}

	function handleScrollTop() {
		const top = document.documentElement.scrollTop || window.scrollY;
		const navHeight = document.getElementsByClassName('navbar')[0]?.offsetHeight;
		if (top > navHeight) {
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		}
	}

	useEffect(() => {
		const dom = document.getElementById('root');
		const cn = new CanvasNest(dom, {
			color: '75, 190, 209',
			opacity: 0.9,
			count: isMobile ? 30 : 400,
		});

		document.addEventListener('click', bindClick);
		return () => {
			cn.destroy();
			document.removeEventListener('click', bindClick);
		};
	}, []);

	const PreLoader = () => {
		return (
			<div id="preloader">
				<div id="status">
					<div className="spinner">
						<div className="bounce1"></div>
						<div className="bounce2"></div>
						<div className="bounce3"></div>
					</div>
				</div>
			</div>
		);
	};

	return (
		<Suspense fallback={<PreLoader />}>
			<BrowserView>
				<Navbar callback={setHide} hide={hide} />
				<Home />
				<Introduction />
				<Advantage />
				<Product />
				<Partner />
				<Footer />
				<div className={styles.page_float} style={{ right: '80px' }}>
					<div className={styles.float_helper} onClick={() => setShow(!show)} id="helper">
						<img src={require('../../assets/images/helper.png')} alt="" />
						{show && (
							<div style={{ right: '-75px' }}>
								<div>
									<img src={require('../../assets/images/Icon_Email.png')} alt="" />
									<span>info@emeraldmining.net</span>
								</div>
								<div>
									<img src={require('../../assets/images/Icon_Telegram.png')} alt="" />
									<span>
										<a href="https://t.me/emeraldmining" target="_blank" rel="noreferrer">
											https://t.me/emeraldmining
										</a>
									</span>
								</div>
							</div>
						)}
					</div>
					<div
						className={styles.scroll_top}
						onClick={handleScrollTop}
						style={{ visibility: hide ? 'visible' : 'hidden' }}
					>
						<FeatherIcon icon="arrow-up" size="24" />
					</div>
				</div>
			</BrowserView>

			<MobileView style={{ height: use100vh() + 'px' }}>
				<NavbarMobile callback={setHide} hide={hide} />
				<HomeMobile />
				<IntroductionMobile />
				<AdvantageMobile />
				<Product />
				<Partner />
				<Footer />
				<div className={styles.page_float} style={{ right: '10px' }}>
					<div className={styles.float_helper} onClick={() => setShow(!show)} id="helper">
						<img src={require('../../assets/images/helper.png')} alt="" />
						{show && (
							<div style={{ right: '-10px' }}>
								<div>
									<img src={require('../../assets/images/Icon_Email.png')} alt="" />
									<span>info@emeraldmining.net</span>
								</div>
								<div>
									<img src={require('../../assets/images/Icon_Telegram.png')} alt="" />
									<span>
										<a href="https://t.me/emeraldmining" target="_blank" rel="noreferrer">
											https://t.me/emeraldmining
										</a>
									</span>
								</div>
							</div>
						)}
					</div>
					<div
						className={styles.scroll_top}
						onClick={handleScrollTop}
						style={{ visibility: hide ? 'visible' : 'hidden' }}
					>
						<FeatherIcon icon="arrow-up" size="24" />
					</div>
				</div>
			</MobileView>
		</Suspense>
	);
}
export default Layout;
