import React, { Component } from 'react';
import routes from '../src/routes';
import { withRouter, Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import './assets/css/pe-icon-7.css';
import './assets/css/materialdesignicons.min.css';
import './assets/scss/themes.scss';

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			<Router>
				<Switch>
					{routes.map((route, idx) => (
						<Route path={route.path} component={route.component} key={idx} />
					))}
				</Switch>
			</Router>
		);
	}
}

export default withRouter(App);
